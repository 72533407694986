import axios from "axios";

axios.defaults.baseURL = "https://aspenapi.azurewebsites.net/"; //production URL (eventually)

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:3000"; //run backend locally
}
//TODO: add token header
axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');

function getAnnouncements() {
  return axios.get("/api/announcements");
}
function getCatalog(catalogId) {
  return axios.get('/api/catalogs/'+catalogId);
}
function getRetailCatalog(dealerId) {
  return axios.get('/api/retailcatalogs/'+dealerId);
}
function getRetailCatalogOrder(dealerId) {
  return axios.get('/api/retailcatalogorder/'+dealerId);
}

function getCatalogByDealer(dealerId) {
  return axios.get('/api/catalogs/dealer/'+dealerId);
}

function getModels() {
  return axios.get("/api/models");
}

function getSeries() {
  return axios.get("/api/series");
}

function getOrders() {
  return axios.get("/api/orders");
}
function getRetailOrders() {
  return axios.get("/api/ordersretail");
}
function getAvailableOrders() {
  return axios.get("/api/availableOrders");
}


function getProductionOrders() {
  return axios.get("/api/productionOrders");
}

function getOptionTypes() {
  return axios.get("/api/optionTypes");
}

function getOptions() {
  return axios.get("/api/options");
}

function getPartTypes() {
  return axios.get("/api/partTypes");
}

function getParts() {
  return axios.get("/api/parts");
}

function getPartOrders() {
  return axios.get("/api/partOrders");
}

function getPartOrder(orderId) {
  return axios.get("/api/partOrder/"+orderId);
}

function getCatalogs() {
  return axios.get("/api/catalogs");
}

function getOrder(orderId) {
  return axios.get("/api/orders/"+orderId);
}

function deleteOrder(orderId) {
  return axios.delete("/api/orders/"+orderId);
}

function getUsers() {
  return axios.get("/api/users");
}

function getDealers() {
  return axios.get("/api/dealers");
}

function getUserx(dealerId) {
  return axios.get("/api/usersForDealer/"+dealerId);
}

function getDealerx(dealerId) {
  return axios.get("/api/dealer/"+dealerId);
}

function getDealerModelCount(dealerId) {
  return axios.get("/api/dealerModelCount/"+dealerId);
}
function getModelCount() {
  return axios.get("/api/modelCount");
}
function getAspelModelCount() {
  return axios.get("/api/aspelModelCount");
}
function getActiveModelCount() {
  return axios.get("/api/activeModelCount");
}
function getActiveAspelModelCount() {
  return axios.get("/api/activeAspelModelCount");
}
function getModelSwimCount() {
  return axios.get("/api/modelSwimCount");
}
function getAspelModelSwimCount() {
  return axios.get("/api/aspelModelSwimCount");
}
function getActiveModelSwimCount() {
  return axios.get("/api/activeModelSwimCount");
}
function getActiveAspelModelSwimCount() {
  return axios.get("/api/activeAspelModelSwimCount");
}
function getDealerSeriesCount(dealerId) {
  return axios.get("/api/dealerSeriesCount/"+dealerId);
}
function getSeriesCount() {
  return axios.get("/api/seriesCount");
}
function getSwimSeriesCount() {
  return axios.get("/api/swimSeriesCount");
}
function getAspelSwimSeriesCount() {
  return axios.get("/api/aspelSwimSeriesCount");
}
function getActiveSwimSeriesCount() {
  return axios.get("/api/activeSwimSeriesCount");
}
function getActiveAspelSwimSeriesCount() {
  return axios.get("/api/activeAspelSwimSeriesCount");
}
function getAspelSeriesCount() {
  return axios.get("/api/aspelSeriesCount");
}
function getActiveSeriesCount() {
  return axios.get("/api/activeSeriesCount");
}
function getActiveAspelSeriesCount() {
  return axios.get("/api/activeAspelSeriesCount");
}
function getOptionCount() {
  return axios.get("/api/optionCount");
}
function getSwimCoverCount() {
  return axios.get("/api/swimCoverCount");
}
function getESOptionCount() {
  return axios.get("/api/esOptionCount");
}
function getSmallESOptionCount() {
  return axios.get("/api/smallesOptionCount");
}
function getGSOptionCount() {
  return axios.get("/api/gsOptionCount");
}
function getVSOptionCount() {
  return axios.get("/api/vsOptionCount");
}
function getHolidayOptionCount() {
  return axios.get("/api/holidayOptionCount");
}
function getAcrylicOptionCount() {
  return axios.get("/api/optionAcrylicCount");
}
function getDealerAcrylicOptionCount(dealerId) {
  return axios.get("/api/optionDealerAcrylicCount/"+dealerId);
}
function getAspelAcrylicOptionCount() {
  return axios.get("/api/aspelOptionAcrylicCount");
}
function getActiveAcrylicOptionCount() {
  return axios.get("/api/activeOptionAcrylicCount");
}
function getActiveAspelAcrylicOptionCount() {
  return axios.get("/api/activeAspelOptionAcrylicCount");
}
function getCabOptionCount() {
  return axios.get("/api/optionCabCount");
}
function getDealerCabOptionCount(dealerId) {
  return axios.get("/api/optionDealerCabCount/"+dealerId);
}
function getAspelCabOptionCount() {
  return axios.get("/api/aspelOptionCabCount");
}
function getSwimCabOptionCount() {
  return axios.get("/api/swimOptionCabCount");
}
function getSwimAspelCabOptionCount() {
  return axios.get("/api/swimAspelOptionCabCount");
}
function getActiveSwimCabOptionCount() {
  return axios.get("/api/activeSwimOptionCabCount");
}
function getActiveSwimAspelCabOptionCount() {
  return axios.get("/api/activeSwimAspelOptionCabCount");
}
function getActiveCabOptionCount() {
  return axios.get("/api/activeOptionCabCount");
}
function getActiveAspelCabOptionCount() {
  return axios.get("/api/activeAspelOptionCabCount");
}
function getCatalogCount() {
  return axios.get("/api/catalogCount");
}
function getStatusCountNoSwimSpas() {
  return axios.get("/api/statusCountNoSwimSpas");
}
function getStatusCountSwimSpas() {
  return axios.get("/api/statusCountSwimSpas");
}
function getDateCountNoSwimSpas() {
  return axios.get("/api/dateCountNoSwimSpas");
}
function getDateCountSwimSpas() {
  return axios.get("/api/dateCountSwimSpas");
}
function getDateCountDealer(dealerId) {
  return axios.get("/api/dateCountDealer/"+dealerId);
}
function getDateCountShipedDealer(dealerId) {
  return axios.get("/api/dateCountShipedDealer/"+dealerId);
}
function getCountDealerOrders(dealerId) {
  return axios.get("/api/countDealerOrders/"+dealerId);
}
function getOrdersWithStatus(status) {
  return axios.get("/api/ordersWithStatus/"+status);
}
function getDealerOrders(status, swim) {
  return axios.get("/api/dealerOrders/"+ status + "/" + swim);
}
function getOrdersIntervals() {
  return axios.get("/api/countOrdersIntervals")
}
function getAspelOrdersIntervals() {
  return axios.get("/api/countAspelOrdersIntervals")
}
function getOrdersIntervalsOpen() {
  return axios.get("/api/countOrdersIntervalsOpen")
}
function getAspelOrdersIntervalsOpen() {
  return axios.get("/api/countAspelOrdersIntervalsOpen")
}
function getSwimOrdersIntervals() {
  return axios.get("/api/countSwimOrdersIntervals")
}
function getSwimOrdersIntervalsOpen() {
  return axios.get("/api/countSwimOrdersIntervalsOpen")
}

function saveRetailCatalog(retailCatalog) {
  return axios
    .post("/api/retailcatalogs", retailCatalog)
    .then(function (response) {
      console.log("successfully created order");
      return response;
    })
    .catch(function (error) {
      console.log("could not create order." + error);
    }); 
}

function createPartOrder(order) {
  return axios
    .post("/api/partOrders", order)
    .then(function (response) {
      console.log("successfully created order");
      return response;
    })
    .catch(function (error) {
      console.log("could not create order." + error);
    });
}

function createOrder(order) {
  return axios
    .post("/api/orders", order)
    .then(function (response) {
      console.log("successfully created order");
      return response;
    })
    .catch(function (error) {
      console.log("could not create order." + error);
    });
}

function editOrder(order) {
  return axios
    .put("/api/orders", order)
    .then(function (response) {
      console.log("successfully updated order");
      return response;
    })
    .catch(function (error) {
      console.log("could not update order." + error);
    });
}

function createRetailOrder(order) {
  return axios
    .post("/api/ordersretail", order)
    .then(function (response) {
      console.log("successfully created order");
      return response;
    })
    .catch(function (error) {
      console.log("could not create order." + error);
    });
}

function editRetailOrder(order) {
  return axios
    .put("/api/ordersretail", order)
    .then(function (response) {
      console.log("successfully updated order");
      return response;
    })
    .catch(function (error) {
      console.log("could not update order." + error);
    });
}

function updatePhase(order) {
  return axios
    .put("/api/updatePhase", order)
    .then(function (response) {
      console.log("successfully updated order phase");
      return response;
    })
    .catch(function (error) {
      console.log("could not update order phase." + error);
    });
}

function updateOrderSlim(order) {
  return axios
    .put("/api/updateOrder", order)
    .then(function (response) {
      console.log("successfully updated order");
      return response;
    })
    .catch(function (error) {
      console.log("could not update order." + error);
    });
}

function updateOrderNotes(order) {
  return axios
    .put("/api/order/updateNotes", order)
    .then(function (response) {
      console.log("successfully updated order");
      return response;
    })
    .catch(function (error) {
      console.log("could not update order." + error);
    });
}

function updateProductionDate(order) {
  return axios
    .put("/api/order/updateProductionStart", order)
    .then(function (response) {
      console.log("successfully updated order");
      return response;
    })
    .catch(function (error) {
      console.log("could not update order." + error);
    });
}

function approveOrder(order) {
  return axios
  .put("/api/approveOrder", order)
  .then(function (response) {
    console.log("successfully approved order");
    return response;
  })
  .catch(function (error) {
    console.log("could not approve order." + error);
    return Promise.reject("failed to approve order.")
  });
}

function updateOrderStatus(order) {
  return axios
  .put("/api/order/updateStatus", order)
  .then(function (response) {
    console.log("successfully updated order status");
    return response;
  })
  .catch(function (error) {
    console.log("could update order status." + error);
    return Promise.reject("failed to update status.")
  });
}

function updateOrderDealerId(order) {
  return axios
  .put("/api/order/updateDealerId", order)
  .then(function (response) {
    console.log("successfully updated order Dealer ID");
    return response;
  })
  .catch(function (error) {
    console.log("could update order Dealer ID." + error);
    return Promise.reject("failed to update Dealer ID.")
  });
}

// Models
function createModel(model) {
  return axios
    .post("api/models", model)
    .then(function (response) {
      console.log("successfully created model" + response);
      return response;
    })
    .catch(function (error) {
      console.log("could not create model." + error);
    });
}

function editModel(model) {
  return axios
    .put("api/models", model)
    .then(function (response) {
      console.log("successfully updated model");
      return response;
    })
    .catch(function (error) {
      console.log("could not update model." + error);
    });
}

// Series
function createSeries(series) {
  return axios
    .post("api/series", series)
    .then(function (response) {
      console.log("successfully created series");
      return response;
    })
    .catch(function (error) {
      console.log("could not create series." + error);
    });
}

function editSeries(series) {
  return axios
    .put("api/series", series)
    .then(function (response) {
      console.log("successfully updated series");
      return response;
    })
    .catch(function (error) {
      console.log("could not update series." + error);
    });
}

// Option Types

function createOptionType(optionType) {
  return axios
    .post("api/option_types", optionType)
    .then(function (response) {
      console.log("successfully created optionType");
      return response;
    })
    .catch(function (error) {
      console.log("could not create optionType." + error);
    });
}

function editOptionType(optionType) {
  return axios
    .put("api/option_types", optionType)
    .then(function (response) {
      console.log("successfully updated optionType");
      return response;
    })
    .catch(function (error) {
      console.log("could not update optionType." + error);
    });
}

// Options

function createOption(option) {
  return axios
    .post("api/options", option)
    .then(function (response) {
      console.log("successfully created option");
      return response;
    })
    .catch(function (error) {
      console.log("could not create option." + error);
    });
}

function editOption(option) {
  return axios
    .put("api/options", option)
    .then(function (response) {
      console.log("successfully updated option");
      return response;
    })
    .catch(function (error) {
      console.log("could not update option." + error);
    });
}

// Part Types

function createPartType(partType) {
  return axios
    .post("api/part_types", partType)
    .then(function (response) {
      console.log("successfully created partType");
      return response;
    })
    .catch(function (error) {
      console.log("could not create partType." + error);
    });
}

function editPartType(partType) {
  return axios
    .put("api/part_types", partType)
    .then(function (response) {
      console.log("successfully updated partType");
      return response;
    })
    .catch(function (error) {
      console.log("could not update partType." + error);
    });
}

// Parts

function createPart(part) {
  return axios
    .post("api/parts", part)
    .then(function (response) {
      console.log("successfully created part");
      return response;
    })
    .catch(function (error) {
      console.log("could not create part." + error);
    });
}

function editPart(part) {
  return axios
    .put("api/parts", part)
    .then(function (response) {
      console.log("successfully updated part");
      return response;
    })
    .catch(function (error) {
      console.log("could not update part." + error);
    });
}

// Users

function editUser(user) {
  return axios
    .put("api/users", user)
    .then(function (response) {
      console.log("successfully updated user");
      return response;
    })
    .catch(function (error) {
      console.log("could not update user." + error);
    });
}

function createUser(user) {
  return axios
    .post("api/users", user)
    .then(function (response) {
      console.log("successfully created user");
      return response;
    })
    .catch(function (error) {
      console.log("could not create user." + error);
    });
}

function editPassword(user) {
  return axios
    .put("api/editPassword", user)
    .then(function (response) {
      console.log("successfully updated password");
      return response;
    })
    .catch(function (error) {
      console.log("could not update password." + error);
    });
}

function resetPassword(userId) {
  console.log(userId);
  return axios
    .put("api/sendResetPassword/"+userId)
    .then(function (response) {
      console.log(response.data);
      console.log("successfully sent password reset address");
      return response;
    })
    .catch(function (error) {
      console.log("could not send password reset address." + error);
    });
}

function resetPasswordEmail(email) {
  return axios
    .put("api/sendResetEmail/"+email)
    .then(function (response) {
      console.log("successfully sent password reset address");
      return response;
    })
    .catch(function (error) {
      console.log("could not send password reset address." + error);
    });
}

function getUser(token) {
  return axios.get("/api/getUserTkn/"+token);
}


// Login

function validateLogin(login) {
  return axios
    .post("api/login", login)
    .then(function (response) {
      console.log("Login Successful");
      return response;
    })
    .catch(function (error) {
      console.log("Login Error." + error);
    });
}

// Dealers

function createDealer(dealer) {
  return axios
    .post("api/dealers", dealer)
    .then(function (response) {
      console.log("successfully created dealer" + response);
      return response;
    })
    .catch(function (error) {
      console.log("could not create dealer." + error);
    });
}

function editDealer(dealer) {
  return axios
    .put("api/dealers", dealer)
    .then(function (response) {
      console.log("successfully updated dealer");
      return response;
    })
    .catch(function (error) {
      console.log("could not update dealer." + error);
    });

}

function getDealersForUser() {
  return axios.get("/api/dealers/user");
}

//Announcement

function createAnnouncement(announcement) {
  return axios
    .post("api/announcements", announcement)
    .then(function (response) {
      console.log("successfully created announcement" + response);
      return response;
    })
    .catch(function (error) {
      console.log("could not create announcement." + error);
    });
}

function editAnnouncement(announcement) {
  return axios
    .put("api/announcements", announcement)
    .then(function (response) {
      console.log("successfully updated announcement");
      return response;
    })
    .catch(function (error) {
      console.log("could not update announcement." + error);
    });

}

function uploadAnnouncementFile(file) {
  let formData = new FormData();
  formData.append("file", file, file.name);

  return axios
    .post("api/announcements/upload", formData)
    .then(function (response) {
      console.log("successfully uploaded announcement file");
      return response;
    })
    .catch(function (error) {
      console.log("could not upload announcement file." + error);
    });

}

// Catalog 
function createCatalogModel(catalogModel) {
  return axios
    .post("api/catalog/addModel", catalogModel)
    .then(function (response) {
      console.log("successfully created catalog model" + response);
      return response;
    })
    .catch(function (error) {
      console.log("could not create catalog model." + error);
    });
}

function createCatalogSeries(catalogSeries) {
  return axios
    .post("api/catalog/addSeries", catalogSeries)
    .then(function (response) {
      console.log("successfully created catalog series" + response);
      return response;
    })
    .catch(function (error) {
      console.log("could not create catalog series." + error);
    });
}

function createCatalogOptionType(catalogOptionType) {
  return axios
    .post("api/catalog/addOptionType", catalogOptionType)
    .then(function (response) {
      console.log("successfully created catalog option type" + response);
      return response;
    })
    .catch(function (error) {
      console.log("could not create catalog option type." + error);
    });
}

function createCatalogOption(catalogOption) {
  return axios
    .post("api/catalog/addOption", catalogOption)
    .then(function (response) {
      console.log("successfully created catalog option" + response);
      return response;
    })
    .catch(function (error) {
      console.log("could not create catalog option." + error);
    });
}

export {
  getCatalog,
  getRetailCatalog,
  getRetailCatalogOrder,
  saveRetailCatalog,
  getCatalogByDealer,
  getOrders,
  getRetailOrders,
  getAvailableOrders,
  getProductionOrders,
  getOrder,
  deleteOrder,
  createOrder,
  editOrder,
  createRetailOrder,
  editRetailOrder,
  updatePhase,
  updateOrderSlim,
  updateOrderStatus,
  updateOrderDealerId,
  updateOrderNotes,
  updateProductionDate,
  approveOrder,
  getModels,
  createModel,
  editModel,
  getSeries,
  createSeries,
  editSeries,
  getOptionTypes,
  getOptions,
  createOptionType,
  editOptionType,
  createOption,
  editOption,
  getCatalogs,
  validateLogin,
  getUsers,
  getUserx,
  editUser,
  createUser,
  editPassword,
  getDealers,
  getDealerx,
  editDealer,
  createDealer,
  getDealerModelCount,
  getModelCount,
  getAspelModelCount,
  getActiveModelCount,
  getActiveAspelModelCount,
  getSeriesCount,
  getDealerSeriesCount,
  getSwimSeriesCount,
  getAspelSwimSeriesCount,
  getActiveSwimSeriesCount,
  getActiveAspelSwimSeriesCount,
  getAspelSeriesCount,
  getActiveSeriesCount,
  getActiveAspelSeriesCount,
  getOptionCount,
  getSwimCoverCount,
  getESOptionCount,
  getSmallESOptionCount,
  getGSOptionCount,
  getVSOptionCount,
  getHolidayOptionCount,
  getAcrylicOptionCount,
  getDealerAcrylicOptionCount,
  getAspelAcrylicOptionCount,
  getActiveAcrylicOptionCount,
  getActiveAspelAcrylicOptionCount,
  getCabOptionCount,
  getDealerCabOptionCount,
  getAspelCabOptionCount,
  getSwimCabOptionCount,
  getSwimAspelCabOptionCount,
  getActiveSwimCabOptionCount,
  getActiveSwimAspelCabOptionCount,
  getActiveCabOptionCount,
  getActiveAspelCabOptionCount,
  getCatalogCount,
  getStatusCountNoSwimSpas,
  getStatusCountSwimSpas,  
  resetPassword,
  resetPasswordEmail,
  getUser,
  createCatalogModel,
  createCatalogSeries,
  createCatalogOptionType,
  createCatalogOption,
  getOrdersWithStatus,
  getDealerOrders,
  getDealersForUser,
  getDateCountNoSwimSpas,
  getDateCountSwimSpas,
  getDateCountDealer,
  getDateCountShipedDealer,
  getCountDealerOrders,
  getModelSwimCount,
  getAspelModelSwimCount,
  getActiveModelSwimCount,
  getActiveAspelModelSwimCount,
  getOrdersIntervals,
  getAspelOrdersIntervals,
  getOrdersIntervalsOpen,
  getAspelOrdersIntervalsOpen,
  getSwimOrdersIntervals,
  getSwimOrdersIntervalsOpen,
  getPartTypes,
  getParts,
  createPartType,
  createPart,
  editPartType,
  editPart,
  getPartOrders,
  getPartOrder,
  createPartOrder,
  getAnnouncements,
  createAnnouncement,
  editAnnouncement,
  uploadAnnouncementFile
};
